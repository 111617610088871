<template>
	<zipped-chart
		:top-right-options="options"
		:title="title"
		:store-name="storeName"
	/>
</template>

<script>
	import ZippedChart from "./ZippedChart.vue";
	export default {
		components: { ZippedChart },
		setup() {
			return {
				title: "P&L Analysis",
                storeName: 'management/GET_QUERY_PARAMS',
				options: [
					{
						value: "/management/p&l/revenue",
						label: "Revenue",
					},
					{
						value: "/management/p&l/opex",
						label: "OPEX",
					},
					{
						value: "/management/p&l/ebit",
						label: "EBIT",
					},
					{
						value: "/management/p&l/pat",
						label: "PAT",
					},
					{
						value: "/management/p&l/patami",
						label: "PATAMI",
					},
				],
			};
		},
	};
</script>

<style scoped>
</style>