<template>
	<sidebar :is-open.sync="isOpenFilter">
		<template v-slot:openButton>
			<div>
				<feather-icon icon="FilterIcon" size="15" />
			</div>
		</template>

		<template v-slot:title>Filter</template>

		<template v-slot:body>
			<div class="customizer-section">
				<b-form-group label="Date Range" label-cols="4">
					<el-select
						v-model="rangeSelected"
						placeholder="Select Date"
					>
						<el-option-group
							v-for="group in rangeOptions"
							:key="group.text"
							:label="group.text"
						>
							<el-option
								v-for="item in group.options"
								:key="item.value"
								:label="item.text"
								:value="item.value"
							>
							</el-option>
						</el-option-group>
					</el-select>

					<el-date-picker
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_YEAR"
						type="year"
						v-model="customYearSelected"
						:editable="false"
						:clearable="false"
					/>
					<el-date-picker
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_MONTH"
						type="month"
						v-model="customMonthSelected"
						:editable="false"
						:clearable="false"
					/>

					<div
						class="mt-1 mb-1"
						v-show="rangeSelected == RANGE_CUSTOM_RANGE_YEAR"
					>
						<div>
							From:
							<el-date-picker
								type="year"
								v-model="customStartYearSelected"
								:editable="false"
								:clearable="false"
							/>
						</div>

						<div class="mt-1">
							To:
							<el-date-picker
								type="year"
								v-model="customEndYearSelected"
								:editable="false"
								:clearable="false"
							/>
						</div>
					</div>
				</b-form-group>

				<b-form-group label="Company" label-cols="4">
					<KECMultipleSelect
						placeholder="Select Company"
						:selected.sync="companyCodeSelected"
						:options="companyState.list"
					/>
				</b-form-group>
			</div>
		</template>
	</sidebar>
</template>

<script>
	import Sidebar from "@common-components/sidebar/Sidebar.vue";
	import { computed, ref, watch } from "@vue/composition-api";
	import {
		BFormGroup,
		BFormSelect,
		BFormRadioGroup,
		BFormCheckbox,
	} from "bootstrap-vue";
	import {
		ManagementRanges,
		RANGE_CUSTOM_YEAR,
		RANGE_CUSTOM_MONTH,
		RANGE_CUSTOM_RANGE_YEAR,
	} from "@configs/filter";
	import store from "@/store";
	import KECMultipleSelect from "@common-components/multiple-select/KECMultipleSelect.vue";
	// import vSelect from "vue-select";

	export default {
		components: {
			BFormGroup,
			BFormSelect,
			Sidebar,

			BFormRadioGroup,
			BFormCheckbox,
			KECMultipleSelect,
			// vSelect,
		},
		setup() {
			const isOpenFilter = ref(false);

			const companyState = computed(
				() => store.getters["app/GET_COMPANY_STATE"]
			);

			const companyCodeSelected = computed({
				get: () => store.state.management.filter.companyCode,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_COMPANY_CODE", val);
				},
			});

			const rangeSelected = computed({
				get: () => store.state.management.filter.range,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_RANGE", val);
				},
			});

			const customYearSelected = computed({
				get: () => store.state.management.filter.customYear,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_CUSTOM_YEAR", val);
				},
			});

			const customMonthSelected = computed({
				get: () => store.state.management.filter.customMonth,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_CUSTOM_MONTH", val);
				},
			});



			const customStartYearSelected = computed({
				get: () => store.state.management.filter.customStartYear,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_CUSTOM_START_YEAR", val);
				},
			});

			const customEndYearSelected = computed({
				get: () => store.state.management.filter.customEndYear,
				set: (val) => {
					store.commit("management/UPDATE_FILTER_CUSTOM_END_YEAR", val);
				},
			});

			return {
				companyState,
				companyCodeSelected,

				rangeOptions: ManagementRanges,
				rangeSelected,

				customYearSelected,
				customMonthSelected,
				customStartYearSelected,
				customEndYearSelected,

				isOpenFilter,

				RANGE_CUSTOM_YEAR,
				RANGE_CUSTOM_MONTH,
				RANGE_CUSTOM_RANGE_YEAR,
			};
		},
	};
</script>

<style scoped>
	.customizer-section {
		padding: 1.5rem;
		border-bottom: 1px solid #ebe9f1;
	}
</style>
