<template>
	<chart
		:title="title"
		:top-right-options="topRightOptions"
		:top-right-selected.sync="topRightSelected"
		:last-update="lastUpdate"
		:options="options"
		:is-loading="isLoading"
		:load-fail="loadFail"
		@reload="onReload"
		@click="onClickChart"
	>
		<template slot="custom-top-right">
			<el-switch v-model="showTop5" active-text="Show Top 5"> </el-switch>

			<el-select
				v-show="topRightOptions != null"
				v-model="topRightSelected"
				placeholder="Select Chart Type"
			>
				<el-option
					v-for="item in topRightOptions"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				>
				</el-option>
			</el-select>
		</template>
	</chart>
</template>

<script>
	import apis from "@configs/apis";
	import Chart from "@common-components/chart/Chart.vue";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { ref, onMounted, watch } from "@vue/composition-api";
	import {
		getZonedDatesFromFullDates,
		getZonedYearMonthFromFullDate,
		getZonedYearFromFullDate,
		getLocalDateTimeString,
	} from "@/utils/date-time-helper";
	import { X_AXIS_MODE_MONTH, X_AXIS_MODE_YEAR } from "@configs/filter";
	import { chartNumberFormatter, chartAmountFormatter } from "@/utils/ui-helper";
	import store from "@/store";
	import dayjs from "dayjs";
	import { formatNumber } from "@/utils/ui-helper";
	import _ from "lodash";

	export default {
		components: {
			Chart,
		},
		props: {
			topRightOptions: {
				type: Array,
			},
			title: {
				type: String,
				required: true,
			},
			storeName: {
				type: String,
				required: true,
			},
		},
		setup(props, ctx) {
			const isLoading = ref(false);
			const loadFail = ref(false);
			const lastUpdate = ref(null);
			const options = ref({});
			const topRightSelected = ref(props.topRightOptions[0].value);
			const showTop5 = ref(false);
			const detailDialog = ref({ isOpen: false, data: [] });

			onMounted(() => {
				fetchChart();
			});

			watch(
				() => showTop5,
				function () {
					fetchChart();
				},
				{ deep: true }
			);

			watch(
				() => topRightSelected,
				function () {
					fetchChart();
				},
				{ deep: true }
			);

			watch(
				() => store.getters[props.storeName],
				function () {
					fetchChart();
				}
			);

			function onReload() {
				fetchChart();
			}

			function formatToK(value) {
				if (value >= 1000 || value <= -1000) {
					return formatNumber(Math.round(value / 1000)) + "k";
				} else {
					return value;
				}
			}

			function setOptions(response, params) {
				let currentLabel;
				let previousLabel;
				if (params.xAxisMode == X_AXIS_MODE_MONTH) {
					currentLabel = getZonedYearFromFullDate(
						params.currentStartDate
					);
					previousLabel = getZonedYearFromFullDate(
						params.previousStartDate
					);
				} else {
					// X_AXIS_MODE_MONTH
					currentLabel =
						getZonedYearFromFullDate(params.currentStartDate) +
						"-" +
						getZonedYearFromFullDate(params.currentEndDate);
					previousLabel =
						getZonedYearFromFullDate(params.previousStartDate) +
						"-" +
						getZonedYearFromFullDate(params.previousEndDate);
				}

				let barData = response.currentBar;
				const xAxisData = Object.keys(barData);
				let memo = {};
				let minTotalValue = 0;
				Object.keys(barData).forEach((date) => {
					const nameMapPerDay = barData[date];
					let dayMinVal = 0;
					Object.keys(nameMapPerDay).forEach((name) => {
						if (memo[name] == null) {
							memo[name] = [];
						}
						memo[name].push(nameMapPerDay[name]);

						if(nameMapPerDay[name] < 0) {
							dayMinVal += nameMapPerDay[name];
						}
					});

					if(dayMinVal < minTotalValue) {
						minTotalValue = dayMinVal;
					}
				});
				let series = Object.keys(memo).map((name) => {
					return {
						name,
						data: memo[name],
						type: "bar",
						stack: "total",
						yAxisIndex: 0,
						zlevel: 3,
						label: {
							show: true,
							formatter: (val) => {
								return formatToK(val.value);
							},
						},
						emphasis: {
							focus: "series",
						},
					};
				});
				series = _.orderBy(
					series,
					(item) => {
						let total = 0;
						item.data.forEach((data) => {
							total += data;
						});
						return total;
					},
					["desc"]
				);
				
				let maxTotalValue = 0;
				series.push({
					name: currentLabel + " (Accumulate)",
					yAxisIndex: 1,
					data: Object.values(response.currentAccumulateLine).map((val) => {
						if(val > maxTotalValue) {
							maxTotalValue = val;
						}
						return Math.round(val);
					}),
					type: "line",
					smooth: true,
					symbol: "none",
					areaStyle: {},
					emphasis: {
						focus: "series",
					},
					zlevel: 1,
					itemStyle: { color: "#f5ca76" },
				});

				series.push({
					name: previousLabel + " (Accumulate)",
					yAxisIndex: 1,
					data: Object.values(response.previousAccumulateLine).map((val) => {
						if(val > maxTotalValue) {
							maxTotalValue = val;
						}
						return Math.round(val)
					}),
					type: "line",
					smooth: true,
					symbol: "none",
					areaStyle: {},
					emphasis: {
						focus: "series",
					},
					zlevel: 0,
					itemStyle: { color: "#dddddd" },
				});

				options.value = {
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
						formatter: (params, ticket) => {
							let total = 0;
							params.forEach((param) => {
								if (param.componentSubType == "bar") {
									total += param.value;
								}
							});

							let result = "";
							params.forEach((param) => {
								if (param.componentSubType == "bar") {
									result +=
										"<div style='padding: 5px'>" +
										param.marker +
										param.seriesName +
										": " +
										formatToK(param.value) +
										" (" +
										(total == 0
											? 0
											: ((param.value * 100) / total).toFixed(
													1
											  )) +
										"%)" +
										"</div>";
								} else {
									result +=
										"<div style='padding: 5px'>" +
										param.marker +
										param.seriesName +
										": " +
										formatToK(param.value) +
										"</div>";
								}
							});

							return (
								result +
								"<div class='mt-1' style='font-weight: bold;'>Click to see detail</div>"
							);
						},
					},
					xAxis: {
						type: "category",
						data: xAxisData,
					},
					yAxis: [
						{
							type: "value",
							axisLabel: {
								formatter: chartAmountFormatter,
							},
							name: "HKD",
							nameLocation: "end",
							// min: minTotalValue,
							// max: maxTotalValue
						},
						{
							type: "value",
							axisLabel: {
								formatter: chartAmountFormatter,
							},
							name: "Accuminlate",
							nameLocation: "end",
							// min: minTotalValue * 2,
							// max: maxTotalValue
						},
					],
					series: series,
				};
			}

			async function fetchChart() {
				isLoading.value = true;
				loadFail.value = false;

				let params = store.getters[props.storeName];

				try {
					const response = await fetcher(
						topRightSelected.value,
						FetchMethod.GET,
						{ ...params, onlyTop5: showTop5.value }
					);

					setOptions(response, params);

					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					console.log(e);
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			}

			function onClickChart(param) {
				let tmp = {};
				let result = {};
				let total = 0;
				options.value.series.forEach((item) => {
					tmp[item.name] = item.data[param.dataIndex];

					if (!item.name.includes("Accumulate")) {
						total += item.data[param.dataIndex];
					}
				});

				options.value.series.forEach((item) => {
					if (!item.name.includes("Accumulate")) {
						result[item.name] =
							total == 0
								? "0%"
								: formatToK(tmp[item.name]) +
								  " (" +
								  ((tmp[item.name] * 100) / total).toFixed(1) +
								  "%)";
					} else {
						result[item.name] = formatToK(tmp[item.name]);
					}
				});

				store.commit("management/UPDATE_TOOLTIPS_STATE", {
					isOpen: true,
					data: result,
				});
			}

			return {
				isLoading,
				loadFail,
				lastUpdate,
				options,
				onReload,

				topRightSelected,
				showTop5,
				onClickChart,
				detailDialog,
			};
		},
	};
</script>
