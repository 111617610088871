<template>
	<VueSlickCarousel v-bind="settings">
		<div class="gap h-100" v-for="component in components" :key="component">
			<component
				:is="component"
				:isLoading="isLoading"
				:loadFail="loadFail"
				:lastUpdate="lastUpdate"
				:value="value"
				:onReload="onReload"
			/>
		</div>
	</VueSlickCarousel>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";

	import EBITMargin from "./EBITMargin.vue";
	import FTE from "./FTE.vue";
	import OPEXRatio from "./OPEXRatio.vue";
	import REVPerFTE from "./REVPerFTE.vue";
	import EBITPerFTE from "./EBITPerFTE.vue";

	import useDataCard from "../useDataCard";
	import apis from "@configs/apis";

	export default {
		components: {
			VueSlickCarousel,

			EBITMargin,
			FTE,
			OPEXRatio,
			REVPerFTE,
			EBITPerFTE,
		},
		setup() {
			const { isLoading, loadFail, lastUpdate, value, onReload } =
				useDataCard(apis.mPnLSummary);

			return {
				isLoading,
				loadFail,
				lastUpdate,
				value,
				onReload,

				components: [
					"EBITMargin",
					"FTE",
					"OPEXRatio",
					"REVPerFTE",
					"EBITPerFTE",
				],
				settings: {
					arrows: false,
					dots: false,
					centerMode: true,
					centerPadding: "0px",
					autoplay: true,
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 5,
					speed: 500,

					responsive: [
						{
							breakpoint: 1400,
							settings: {
								slidesToShow: 5,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 1300,
							settings: {
								slidesToShow: 4,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 900,
							settings: {
								slidesToShow: 3,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 700,
							settings: {
								slidesToShow: 2,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
						{
							breakpoint: 590,
							settings: {
								slidesToShow: 1,
								arrows: true,
								dots: true,
								centerPadding: "50px",
							},
						},
					],
				},
			};
		},
	};
</script>

<style scoped>
	.gap {
		padding-left: 10px;
		padding-right: 10px;
	}
</style>