<template>
	<div id="root">
		<management-filter />

		<el-dialog
			title="Details"
			:visible.sync="isTooltipsOpen"
			width="50%"
			center
		>
			<div v-for="name in Object.keys(tooltipsData)" :key="name" class="m-1">
				{{name}}: {{tooltipsData[name]}}
			</div>
		</el-dialog>

		<el-button
			style="align-self: end"
			type="text"
			@click="isOpenUploadDialog = true"
			>Upload Excel File</el-button
		>
		<el-dialog
			title="Upload Excel file"
			:visible.sync="isOpenUploadDialog"
			width="30%"
			center
		>
			<div
				style="
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				"
			>
				<el-upload
					:action="uploadUrl"
					:headers="myHeaders"
					:on-success="onUploadSuccess"
					:on-error="onUploadFail"
					class="upload-demo"
					drag
					accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/excel, application/vnd.ms-excel, application/vnd.msexcel"
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						Drag and drop / <em>click upload</em>
					</div>
					<div class="el-upload__tip" slot="tip">
						Only accept Excel file
					</div>
				</el-upload>

				<div
					v-show="uploadError != null"
					style="color: red; font-weight: bold"
					class="mt-1"
				>
					Error: {{ uploadError }}
				</div>
			</div>
		</el-dialog>

		<data-card-list />

		<div class="pb-2" style="flex: 1" ref="element">
			<VueSlickCarousel v-bind="settings" class="remaining-area pt-1">
				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-12 p-1 h-100">
							<profit-chart />
						</div>
					</div>
				</div>

				<div class="container-fluid p-0 pl-1 pr-1 h-100">
					<div class="row h-100 align-items-stretch">
						<div class="col-12 p-1 h-100">
							<cost-chart />
						</div>
					</div>
				</div>
			</VueSlickCarousel>
		</div>
	</div>
</template>

<script>
	import VueSlickCarousel from "vue-slick-carousel";
	import ManagementFilter from "./components/ManagementFilter.vue";
	import DataCardList from "./components/data-cards/p&l/DataCardList.vue";
	import ProfitChart from "./components/charts/p&l/ProfitChart.vue";
	import CostChart from "./components/charts/p&l/CostChart.vue";
	import sizeHook from "@common-components/sizeHook";
	import { getCurrentInstance, ref, computed } from "@vue/composition-api";
	import apis from "@configs/apis";
	import { axiosBaseUrl } from "@/libs/axios";
	import { cleanAccessToken, getAccessToken } from "@/utils/account-localstorage";
	import store from '@/store';

	export default {
		components: {
			ManagementFilter,
			DataCardList,
			VueSlickCarousel,
			ProfitChart,
			CostChart,
		},
		setup(props, ctx) {
			const [size, element] = sizeHook();
			const isOpenUploadDialog = ref(false);
			const uploadError = ref(null);

			const onUploadSuccess = (data) => {
				if (data.success) {
					window.location.reload();
				} else {
					onUploadFail(data);
				}
			};

			const onUploadFail = (data) => {
				uploadError.value = data;
			};

			const isTooltipsOpen = computed({
				get() {
					return store.state.management.tooltipsState.isOpen;
				},
				set(val) {
					store.state.management.tooltipsState = { isOpen: false, data: [] }
				}
			})

			const tooltipsData = computed(() => store.state.management.tooltipsState.data);

			return {
				size,
				element,

				isOpenUploadDialog,
				uploadUrl: axiosBaseUrl + apis.mPnLUploadExcel,
				myHeaders: { Authorization: `Bearer ${getAccessToken()}` },
				onUploadSuccess,
				onUploadFail,
				uploadError,

				isTooltipsOpen,
				tooltipsData,

				settings: {
					lazyLoad: "ondemand",
					arrows: true,
					dots: true,
					centerMode: true,
					centerPadding: "50px",
					focusOnSelect: true,
					infinite: true,
					slidesToShow: 1,
					speed: 500,
				},
			};
		},
	};
</script>

<style scoped>
	#root {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
</style>
