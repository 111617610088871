<template>
	<zipped-chart
		:top-right-options="options"
		:title="title"
		:store-name="storeName"
	/>
</template>

<script>
	import ZippedChart from "./ZippedChart.vue";
	export default {
		components: { ZippedChart },
		setup() {
			return {
				title: "Cost Analysis",
                storeName: 'management/GET_QUERY_PARAMS',
				options: [
					{
						value: "/management/p&l/staff-cost",
						label: "Staff Costs",
					},
					{
						value: "/management/p&l/direct-labour-cost",
						label: "Direct Labour Costs",
					},
					{
						value: "/management/p&l/warehouse-cost",
						label: "Warehouse Costs",
					},
					{
						value: "/management/p&l/cbt-cost",
						label: "CBT Costs",
					},
					{
						value: "/management/p&l/other-cost",
						label: "Other DOC",
					},
					{
						value: "/management/p&l/admin-cost",
						label: "Admin Costs",
					},
					{
						value: "/management/p&l/depreciation",
						label: "CAPEX Depreciation",
					},
					{
						value: "/management/p&l/interest",
						label: "Interest",
					},
					{
						value: "/management/p&l/tax",
						label: "Tax",
					},
				],
			};
		},
	};
</script>

<style scoped>
</style>